import repository from "@config/repository";
import MessageRepository from "@domain/repositories/message-repository";

import { SearchMessageWithinConversationQuery } from "@domain/interfaces/message-interface";

export default function searchMessageWithinConversationUseCase (query: SearchMessageWithinConversationQuery) {
  const messageRepository = repository.get<MessageRepository>(MessageRepository);

  return messageRepository.searchMessageWithinConversation(query);
}
