import { UpdateConversationDetailsBody } from "@domain/interfaces/conversation-details-interface";
import {
  ConversationAction,
  CreateConversationBody,
  CreateConversationResponse,
  GetConversationDetailResponse,
  GetConversationListResponse,
  GetConversationMediaResponse,
  GetConversationMemberQuery,
  GetConversationMemberResponse,
  GetMediaListQuery,
  GetSaveMessageConversationResponse,
  GetUserConversationQuery,
  RemoveConversationResponse,
  SearchConversationQuery,
  SearchConversationResponse,
  UpdateConversationBody,
  UpdateConversationResponse,
} from "@domain/interfaces/conversation-interface";
import { socket } from "@infrastructure/network/socket";
import { injectable } from "inversify";

import { DEFAULT_TIMEOUT } from "@common/constant";
import { GetMessageDetailQuery } from "@domain/interfaces/message-interface";
import ConversationRepository from "@domain/repositories/conversation-repository";

@injectable()
export default class ConversationApiRepository implements ConversationRepository {
  getList (query: GetUserConversationQuery): Promise<GetConversationListResponse> {
    return new Promise((resolve, reject) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("conversation:getList", query, (error, data) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }

  getPinList (query: GetUserConversationQuery): Promise<GetConversationListResponse> {
    return new Promise((resolve, reject) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("conversation:getPinList", query, (error, data) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }

  getDetail (query: GetMessageDetailQuery): Promise<GetConversationDetailResponse> {
    return new Promise((resolve, reject) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("conversation:getDetail", query, (error, data) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }

  getSaveMessageConversation (): Promise<GetSaveMessageConversationResponse> {
    return new Promise((resolve, reject) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("conversation:createSaveMessage", {}, (error, data) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }

  create (body: CreateConversationBody): Promise<CreateConversationResponse> {
    return new Promise((resolve, reject) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("conversation:create", body, (error, data) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }

  updateConversationDetails (body: UpdateConversationDetailsBody): Promise<UpdateConversationResponse> {
    return new Promise((resolve, reject) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("conversationDetails:update", body, (error, data: UpdateConversationResponse) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }

  updateConversation (action: ConversationAction, body: UpdateConversationBody): Promise<UpdateConversationResponse> {
    return new Promise((resolve, reject) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("conversation:update", action, body, (error, data: UpdateConversationResponse) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }

  removeConversation (id: string): Promise<RemoveConversationResponse> {
    return new Promise((resolve, reject) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("conversation:remove", { id }, (error, data: RemoveConversationResponse) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }

  getMediaList (query: GetMediaListQuery): Promise<GetConversationMediaResponse> {
    return new Promise((resolve, reject) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("conversation:getMediaList", query, (error, data: GetConversationMediaResponse) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }

  getFileList (query: GetMediaListQuery): Promise<GetConversationMediaResponse> {
    return new Promise((resolve, reject) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("conversation:getFileList", query, (error, data: GetConversationMediaResponse) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }

  search (query: SearchConversationQuery): Promise<SearchConversationResponse> {
    return new Promise((resolve, reject) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("conversation:search", query, (error, data: SearchConversationResponse) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }

  getMember (query: GetConversationMemberQuery): Promise<GetConversationMemberResponse> {
    return new Promise((resolve, reject) => {
      socket.timeout(DEFAULT_TIMEOUT).emit("conversation:getMembers", query, (error, data) => {
        if (error) {
          reject(error);
        }
        resolve(data);
      });
    });
  }
}
