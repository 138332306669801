export const DMY_HMS = "DD/MM/YYYY hh:mm:ss";

export const DMY_HM = "DD/MM/YYYY hh:mm";

export const YMD = "YYYY/MM/DD";

export const MDY = "MM/DD/YYYY";

export const HMS = "hh:mm:ss";

export const DMY = "DD/MM/YYYY";

export const YMD_HMS = "YYYY-MM-DD hh:mm:ss";

export const HM = "hh:mm";

export function getEndDay (input?: string | Date) {
  const date = input ? new Date(input) : new Date();
  date.setHours(23, 59, 59, 999);

  return date;
}

export function getStartDay (input?: string | Date) {
  const date = input ? new Date(input) : new Date();
  date.setHours(0, 0, 0, 0);

  return date;
}

export function dateToString (input?: Date | string, format?: string) {
  if (!input) return "";

  const fullTime = new Date(input);
  const month = fullTime.getMonth() + 1;
  const date = fullTime.getDate();
  const year = fullTime.getFullYear();
  const hours = fullTime.getHours();
  const minutes = fullTime.getMinutes();
  const seconds = fullTime.getSeconds();

  const time = {
    year,
    date: date < 10 ? `0${date}` : date,
    month: month < 10 ? `0${month}` : month,
    hour: hours < 10 ? `0${hours}` : hours,
    minute: minutes < 10 ? `0${minutes}` : minutes,
    seconds: seconds < 10 ? `0${seconds}` : seconds,
  };

  if (!format || format === DMY) return `${time.date}/${time.month}/${time.year}`;

  if (format === DMY_HMS) {
    return `${time.date}/${time.month}/${time.year} ${time.hour}:${time.minute}:${time.seconds}`;
  }

  if (format === DMY_HM) {
    return `${time.date}/${time.month}/${time.year} ${time.hour}:${time.minute}`;
  }

  if (format === YMD) return `${time.year}/${time.month}/${time.date}`;

  if (format === MDY) return `${time.month}/${time.date}/${time.year}`;

  if (format === HMS) return `${time.hour}:${time.minute}:${time.seconds}`;

  if (format === YMD_HMS) {
    return `${time.year}/${time.month}/${time.date} ${time.hour}:${time.minute}:${time.seconds}`;
  }

  if (format === MDY) return `${time.month}/${time.date}/${time.year}`;

  if (format === HM) return `${time.hour}:${time.minute}`;

  return "";
}

export function getDiffTimeText (time: any, format?: string) {
  let inputTime = time;

  if (format === DMY) inputTime = time.split("/").reverse().join("-");

  const createdTime = new Date(inputTime);
  const now = new Date();
  const diffTime = now.getTime() - createdTime.getTime();
  const diffSecond = Math.ceil(diffTime / 1000);
  const diffMinutes = Math.ceil(diffTime / (1000 * 60));
  const diffHour = Math.ceil(diffTime / (1000 * 60 * 60));
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  if (diffSecond < 60) return `${diffSecond} giây trước`;

  if (diffMinutes < 60) return `${diffMinutes} phút trước`;

  if (diffHour <= 24) return `${diffHour} giờ trước`;

  if (diffDays > 1 && diffDays < 7) {
    return `${diffDays} ngày`;
  }

  return dateToString(createdTime);
}

export function getTimer (seconds?: number) {
  if (!seconds) return "00:00:00";
  const hour = Math.floor(seconds / 3600);
  const minute = Math.floor((seconds % 3600) / 60);
  const second = Math.floor((seconds % 3600) % 60);

  return `${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}:${second < 10 ? `0${second}` : second}`;
}
