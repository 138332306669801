/* eslint-disable @typescript-eslint/no-unused-vars */
import ErrorCode from "@common/kernel/error-code";

import { InternalError } from "@common/kernel/custom-error";
import { TheAdvanceMediaResponse } from "@infrastructure/network/theadvance-media";

export default abstract class MediaRepository {
  uploadMedia (file: File): Promise<TheAdvanceMediaResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  uploadRecord (data: Blob, duration = 0): Promise<TheAdvanceMediaResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  uploadAvatar (file: File): Promise<TheAdvanceMediaResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
