/* eslint-disable @typescript-eslint/no-unused-vars */
import ErrorCode from "@common/kernel/error-code";

import { InternalError } from "@common/kernel/custom-error";
import { UpdateConversationDetailsBody, UpdateConversationDetailsResponse } from "@domain/interfaces/conversation-details-interface";
import {
  ConversationAction,
  CreateConversationBody,
  CreateConversationResponse,
  GetConversationDetailResponse,
  GetConversationListResponse,
  GetConversationMediaResponse,
  GetConversationMemberQuery,
  GetConversationMemberResponse,
  GetMediaListQuery,
  GetSaveMessageConversationResponse,
  GetUserConversationQuery,
  RemoveConversationResponse,
  SearchConversationQuery,
  SearchConversationResponse,
  UpdateConversationBody,
  UpdateConversationResponse,
} from "@domain/interfaces/conversation-interface";
import { GetMessageDetailQuery } from "@domain/interfaces/message-interface";

export default abstract class ConversationRepository {
  getDetail (query: GetMessageDetailQuery): Promise<GetConversationDetailResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  getList (query: GetUserConversationQuery): Promise<GetConversationListResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  getPinList (query: any): Promise<GetConversationListResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  getSaveMessageConversation (): Promise<GetSaveMessageConversationResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  create (body: CreateConversationBody): Promise<CreateConversationResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  updateConversationDetails (body: UpdateConversationDetailsBody): Promise<UpdateConversationDetailsResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  updateConversation (action: ConversationAction, body: UpdateConversationBody): Promise<UpdateConversationResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  removeConversation (id: string): Promise<RemoveConversationResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  getMediaList (query: GetMediaListQuery): Promise<GetConversationMediaResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  getFileList (query: GetMediaListQuery): Promise<GetConversationMediaResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  search (query: SearchConversationQuery): Promise<SearchConversationResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }

  getMember (query: GetConversationMemberQuery): Promise<GetConversationMemberResponse> {
    throw new InternalError(ErrorCode.ERR_METHOD_NOT_IMPLEMENTED);
  }
}
