import connectLoginNamespaceUseCase from "@domain/use-cases/account/connect-login-namespace";
import connectUserSocketUseCase from "@domain/use-cases/account/connect-socket";
import loginUseCase from "@domain/use-cases/account/login";

import Account from "@domain/entities/account-entity";
import { IAccountLoginBody } from "@domain/interfaces/account-interface";
import { ClientToServerEvents, ServerToClientEvents } from "@domain/interfaces/socket-interface";
import { defineStore } from "pinia";
import { CornerDotType, CornerSquareType, DotType, DrawType, ErrorCorrectionLevel, Mode, Options, TypeNumber } from "qr-code-styling";
import { Socket } from "socket.io-client";
import { useAlertStore } from "./alert-store";

export const useAccountStore = defineStore("account", {
  state: () => ({
    loading: false,
    loginUser: {} as Account,
    token: "",
    socket: {} as Socket<ServerToClientEvents, ClientToServerEvents>,
    qrCodeOptions: {} as Partial<Options>,
  }),

  getters: {
    getLoginUser: (state) => state.loginUser,
    isLogin: (state) => !!state.loginUser.id,
  },
  persist: {
    key: "dataAccess",
    paths: ["loginUser", "token"],
  },
  actions: {
    loginSuccess (accessToken: string, user: Account) {
      if (user.name) {
        const alert = useAlertStore();
        alert.success(`Welcome back ${user.name}!`);
      }

      this.socket = connectUserSocketUseCase(this.token);
      this.loginUser = user;
      this.token = accessToken;
    },
    getQrCodeOptions () {
      const socket = connectLoginNamespaceUseCase();
      socket.on("connect", () => {
        this.qrCodeOptions = {
          width: 300,
          height: 300,
          type: "svg" as DrawType,
          data: JSON.stringify({
            type: "loginChatWeb",
            values: {
              socketId: socket.id,
            },
            data: {
              socketId: socket.id,
            },
          }),
          image: "/favicon.ico",
          margin: 10,
          qrOptions: {
            typeNumber: 0 as TypeNumber,
            mode: "Byte" as Mode,
            errorCorrectionLevel: "Q" as ErrorCorrectionLevel,
          },
          imageOptions: {
            hideBackgroundDots: true,
            imageSize: 0.4,
            margin: 20,
            crossOrigin: "anonymous",
          },
          dotsOptions: {
            color: "var(--color-primary-500)",
            // gradient: {
            //   type: 'linear', // 'radial'
            //   rotation: 0,
            //   colorStops: [{ offset: 0, color: '#8688B2' }, { offset: 1, color: '#77779C' }]
            // },
            type: "rounded" as DotType,
          },
          backgroundOptions: {
            color: "var(--color-secondary-100)",
            // gradient: {
            //   type: "linear", // 'radial'
            //   rotation: 0,
            //   colorStops: [
            //     { offset: 0, color: "#ededff" },
            //     { offset: 1, color: "#e6e7ff" },
            //   ],
            // },
          },
          cornersSquareOptions: {
            color: "#35495E",
            type: "extra-rounded" as CornerSquareType,
            // gradient: {
            //   type: 'linear', // 'radial'
            //   rotation: 180,
            //   colorStops: [{ offset: 0, color: '#25456e' }, { offset: 1, color: '#4267b2' }]
            // },
          },
          cornersDotOptions: {
            color: "#35495E",
            type: "dot" as CornerDotType,
            // gradient: {
            //   type: 'linear', // 'radial'
            //   rotation: 180,
            //   colorStops: [{ offset: 0, color: '#00266e' }, { offset: 1, color: '#4060b3' }]
            // },
          },
        } as Partial<Options>;
      });
      socket.on("qrCodeExpired", () => {
        console.log("qrCodeExpired");
      });
      socket.on("loginSuccess", (result: { accessToken: string; user: Account }) => {
        socket.disconnect();
        this.loginSuccess(result.accessToken, result.user);
      });
    },
    async login (data: IAccountLoginBody, sign: string) {
      const result = await loginUseCase(data, sign);

      if (result.code !== 1 || !result.data || !result.data.user?.username) return false;

      this.loginSuccess(result.data.accessToken, result.data.user);

      return true;
    },
    async logout () {
      this.loginUser = {} as Account;
      this.token = "";
      window.location.reload();
    },
  },
});
