import { getAccessToken } from "@common/storage/get-access-token";
import { ClientToServerEvents, ServerToClientEvents } from "@domain/interfaces/socket-interface";
import { Socket, io } from "socket.io-client";
import { handleDebug } from "./helper";

import onConnected from "./listen-event/connect";
import onNewMessage from "./listen-event/new-message";
import onSendReaction from "./listen-event/send-reaction";

export let socket: Socket<ServerToClientEvents, ClientToServerEvents>;

export const connectUserSocket = (accessToken: string) => {
  const socketIO: Socket<ServerToClientEvents, ClientToServerEvents> = io(gConfig.api.host, {
    auth: {
      product: gConfig.api.product,
      token: gConfig.api.token,
      "access-token": accessToken || getAccessToken(),
    },
    reconnectionDelayMax: 20000,
    query: {},
    withCredentials: true,
    reconnectionDelay: 2000,
    transports: ["polling", "websocket"],
  });

  socket = socketIO;

  socket.on("connect_error", (error: any) => {
    handleDebug("connect_error", error);
    try {
      // const err = <ISocketResponse>JSON.parse(JSON.stringify(error.data));
      // if (err?.code === ErrorCode.SESSION_TIMEOUT.code) {
      //   useAccountStore().logout();
      //   window.location.reload();
      // }
    } catch (error) {
      console.log(error);
    }
  });

  socket.on("disconnect", (error: any) => {
    handleDebug("disconnect", error);
  });

  socket.on("error", (error: any) => {
    handleDebug("error", error);
  });

  onConnected(socket);
  onNewMessage(socket);
  onSendReaction(socket);

  return socketIO;
};

export const connectLoginSocket = () => {
  const socketIO: Socket<ServerToClientEvents, ClientToServerEvents> = io(gConfig.api.host + "/login", {
    auth: {
      product: gConfig.api.product,
      token: gConfig.api.token,
    },
    reconnectionDelayMax: 20000,
    query: {},
    withCredentials: true,
    reconnectionDelay: 2000,
    transports: ["polling", "websocket"],
  });

  return socketIO;
};
