import repository from "@config/repository";
import ConversationRepository from "@domain/repositories/conversation-repository";

import { GetConversationMemberQuery } from "@domain/interfaces/conversation-interface";

export default async function getConversationMemberUseCase (body: GetConversationMemberQuery) {
  const conversationRepository = repository.get<ConversationRepository>(ConversationRepository);

  return await conversationRepository.getMember(body);
}
