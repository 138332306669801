import Message from "@domain/entities/message-entity";

import { getFileUrl } from "@common/file";
import { IMention, IMessage, MessageActions, QuoteInfo } from "@domain/interfaces/message-interface";
import { useAccountStore } from "@presentation/store/account-store";

export default function createClientMessageUseCase (data: {
  conversationId: string;
  content: string;
  requestId: string;
  files?: File[];
  action?: MessageActions;
  rootMessage?: IMessage;
  mention?: IMention[];
  quote?: QuoteInfo;
  record?: {
    blob?: Blob;
    duration?: number;
  };
}) {
  const accountStore = useAccountStore();
  const attachments = () => {
    if (data.files) {
      return data.files.map((file) => ({
        id: "",
        link: getFileUrl(file),
        mimetype: file.type,
        size: file.size,
        originalname: file.name,
        thumbnail: "",
        duration: 0,
        height: 0,
        width: 0,
      }));
    }

    if (data.record && data.record.blob) {
      return [
        {
          id: "",
          link: window.URL.createObjectURL(data.record.blob),
          mimetype: data.record.blob.type,
          size: data.record.blob.size,
          originalname: data.record.blob.name,
          thumbnail: window.URL.createObjectURL(data.record.blob),
          duration: data.record.duration,
          height: 0,
          width: 0,
        },
      ];
    }

    return [];
  };

  return new Message({
    id: "",
    content: data.content,
    conversationId: data.conversationId,
    requestId: new Date().getTime().toString(),
    createdBy: accountStore.loginUser.username,
    createdByInfo: {
      username: accountStore.loginUser.username,
      avatar: accountStore.loginUser.avatar,
      id: accountStore.loginUser.id,
      name: accountStore.loginUser.name,
      type: accountStore.loginUser.type,
      phone: "",
      departmentName: "",
    },
    quote: data.quote,
    replyInfo: data.rootMessage,
    forward: data.rootMessage,
    mention: data.mention || [],
    attachment: attachments(),
  });
}
