import { API_SYS_ERROR } from "@common/kernel/error-code";
import axios, { AxiosError } from "axios";

export class TheAdvanceMediaResponse {
  status: number;
  code: number;
  message: string;
  data: {
    filename: string;
    id: string;
    link: string;
    thumbnail: string;
    height: number;
    width: number;
    duration?: number;
  };

  constructor (response?: { status: number; code: number; message: string; data: any }) {
    this.code = response?.code || API_SYS_ERROR.code;
    this.status = response?.status || API_SYS_ERROR.status;
    this.message = response?.message || API_SYS_ERROR.message;
    this.data = {
      filename: response?.data?.filename || "",
      id: response?.data?.id || "",
      link: response?.data?.link || "",
      thumbnail: response?.data?.thumbnail || "",
      height: response?.data?.height || 0,
      width: response?.data?.width || 0,
      duration: response?.data?.duration || 0,
    };
  }
}

export default class TheAdvanceMediaService {
  host: string;
  headers: object;

  constructor () {
    this.host = gConfig.theAdvanceMedia.host;
    this.headers = {
      product: gConfig.theAdvanceMedia.product,
      token: gConfig.theAdvanceMedia.token,
    };
  }

  async upload (path: string, file: File | Blob, fileName: string, duration = 0) {
    try {
      const formData = new FormData();
      formData.append("file", file, fileName);
      formData.append("duration", duration.toString());
      formData.append("filename", fileName);

      const headers = { ...this.headers, authorization: "" };
      const url = `${this.host}${path}`;
      const response = await axios.post(url, formData, { headers });

      return new TheAdvanceMediaResponse(response.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const err = error as AxiosError;

        return new TheAdvanceMediaResponse(err.response?.data as TheAdvanceMediaResponse);
      }

      return new TheAdvanceMediaResponse();
    }
  }
}
