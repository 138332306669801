import { getConversationNameFromUsers } from "@domain/helpers/conversation-helper";
import { scrollToMessage } from "@domain/helpers/message-helper";
import { getLastName } from "@domain/helpers/user-helper";
import { Chat } from "@domain/interfaces/conversation-interface";
import { IMention, IMessage, MessageActions, MessageAttachment } from "@domain/interfaces/message-interface";
import { IUser } from "@domain/interfaces/user-interface";
import { defineStore } from "pinia";
import { useAccountStore } from "./account-store";
import { useConversationStore } from "./conversation-store";
import { useSearchStore } from "./search-store";

import getBlobDuration from "get-blob-duration";
import RecordRTC from "recordrtc";

type ConversationAction = "addMember" | "forward" | undefined;

type RightSidebarSlot = "groupInfo" | "userInfo" | "setting" | undefined;

type ChatDialogSlot = "chat" | "pinMessage" | "selectContact" | "createGroup" | undefined;

export const useAppStateStore = defineStore("app-state", {
  state: () => ({
    record: {
      visible: false,
      stream: undefined as MediaStream | undefined,
      recorder: undefined as RecordRTC | undefined,
      currentSecond: 0 as number,
      timer: undefined as any,

      player: undefined as HTMLAudioElement | undefined,
      recordOwner: "" as string,
      playerStatus: "pause" as "pause" | "playing",
      percentagePlayed: 0,
      recordTracks: [] as {
        player: HTMLAudioElement;
        audio: MessageAttachment;
        message: IMessage;
      }[],
    },
    userChatModal: {
      visible: false,
      action: undefined as ConversationAction,
      selectedList: [] as Chat[],
      fetching: false,
    },
    contact: {
      selectedItems: [] as IUser[],
    },
    createGroup: {
      groupName: "",
      avatar: undefined,
      groupNameRef: "",
    },
    reactionMessageModalVisible: false,
    messageAction: "" as MessageActions | undefined,
    selectedMessage: {} as IMessage,
    quoteText: "",
    rightSidebar: {
      visible: false,
      slot: undefined as RightSidebarSlot,
    },
    mention: [] as IMention[],
    chatDialogSlot: "" as ChatDialogSlot,
  }),
  actions: {
    resetState () {
      useAppStateStore().$reset();
    },
    setSelectedMessage (message?: IMessage) {
      this.selectedMessage = message || ({} as IMessage);
    },
    setQuoteText (quoteText: string) {
      this.quoteText = quoteText;
    },
    openChatUserModal (action: ConversationAction) {
      this.userChatModal = {
        visible: true,
        action,
        selectedList: [],
        fetching: false,
      };
      useSearchStore().conversation.items = useConversationStore().data.conversations.items.map((item) => ({
        ...item,
        type: "chat",
        username: "",
      }));
    },
    closeChatUserModal () {
      this.userChatModal = {
        visible: false,
        action: undefined,
        selectedList: [],
        fetching: false,
      };
    },
    closeMessageAction () {
      this.messageAction = undefined;
      this.quoteText = "";
      this.selectedMessage = {} as IMessage;
    },
    openMessageAction (action: MessageActions) {
      this.messageAction = action;
    },
    openReactionMessageModal () {
      this.reactionMessageModalVisible = true;
    },
    closeReactionMessageModal () {
      this.reactionMessageModalVisible = false;
    },
    openRightSidebar (slot: RightSidebarSlot) {
      this.rightSidebar.slot = slot;
      this.rightSidebar.visible = true;
    },
    closeRightSidebar () {
      this.rightSidebar.slot = undefined;
      this.rightSidebar.visible = false;
    },
    focusChatInput () {
      document.getElementById("chat-input")?.focus();
    },
    openChatDialog () {
      this.chatDialogSlot = "chat";
    },
    openPinMessage () {
      this.chatDialogSlot = "pinMessage";
    },
    closePinMessage () {
      this.chatDialogSlot = "chat";
      scrollToMessage(useConversationStore().selectedConversation.conversationDetails?.lastSeenMessageId);
    },
    openSelectContact () {
      this.chatDialogSlot = "selectContact";
    },
    closeSelectContact () {
      this.chatDialogSlot = undefined;
    },
    openCreateGroup () {
      this.chatDialogSlot = "createGroup";
      const ownerName = getLastName(useAccountStore().loginUser.name);
      this.createGroup.groupName = getConversationNameFromUsers(this.contact.selectedItems);
      this.createGroup.groupName = this.createGroup.groupName.trim().length > 0 ? `${ownerName}, ${this.createGroup.groupName}` : ownerName;

      setTimeout(() => {
        document.getElementById("input-group-name")?.focus();
      }, 200);
    },
    closeCreateGroup () {
      this.chatDialogSlot = undefined;
      this.contact.selectedItems = [];
    },
    async onStartRecord () {
      this.record.stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      this.record.recorder = new RecordRTC(this.record.stream, {
        type: "audio",
        mimeType: "audio/wav",
        recorderType: RecordRTC.StereoAudioRecorder, // force for all browsers
        numberOfAudioChannels: 2,
      });

      this.record.recorder.startRecording();
      this.record.timer = setInterval(() => {
        this.record.currentSecond = this.record.currentSecond + 1;
      }, 1000);
      this.record.visible = true;
    },
    async getRecordFile () {
      return new Promise((resolve, reject) => {
        this.record.recorder?.stopRecording(async () => {
          this.onStopRecording();
          const blob = this.record.recorder?.getBlob();

          if (blob) {
            resolve({
              blob,
              duration: blob ? await getBlobDuration(blob) : 0,
            });
          }
          reject(new Error("Record file error"));
        });
      }) as Promise<{ blob?: Blob; duration?: number }>;
    },
    async onStopRecording () {
      this.record.visible = false;
      this.record.currentSecond = 0;
      clearInterval(this.record.timer);
      this.record.timer = undefined;
      this.record.stream?.getTracks().forEach((track: MediaStreamTrack) => track.stop());
    },
    async playRecordPlayer (player: HTMLAudioElement, recordOwner: string) {
      if (this.record.player && player !== this.record.player) {
        this.stopRecordPlayer();
      }

      this.record.player = player;
      this.record.recordOwner = recordOwner;
      this.record.playerStatus = "playing";
      await this.record.player.play();
    },
    async pauseRecordPlayer (player: HTMLAudioElement) {
      this.record.player = player;
      player.pause();
      this.record.playerStatus = "pause";
    },
    async onEndRecordPlayer () {
      this.record.playerStatus = "pause";
      this.record.percentagePlayed = 0;
    },
    stopRecordPlayer () {
      if (this.record.player) {
        this.record.player.pause();
        this.record.player.currentTime = 0;
        this.record.playerStatus = "pause";
      }
    },
    closeRecordPlayer () {
      if (this.record.player) {
        this.stopRecordPlayer();
        this.record.player = undefined;
      }
    },
    addRecordTrack (data: { player: HTMLAudioElement; audio: MessageAttachment; message: IMessage }) {
      this.record.recordTracks = this.record.recordTracks
        .concat([data])
        .sort((a, b) => new Date(a.message.createdAt).getTime() - new Date(b.message.createdAt).getTime());
    },
    removeRecordTrack (message: { id?: string; requestId?: string }) {
      if (message.id) {
        if (this.record.player === this.record.recordTracks.find((track) => track.message.id === message.id)?.player) {
          this.closeRecordPlayer();
        }

        this.record.recordTracks = this.record.recordTracks.filter((track) => track.message.id !== message.id);
      }

      // xoa recordTrack theo requestId
      if (message.requestId) {
        this.record.recordTracks = this.record.recordTracks.filter((track) => track.message.requestId !== message.requestId);
      }
    },
  },
});
