import repository from "@config/repository";
import MessageRepository from "@domain/repositories/message-repository";

import { SearchMessageQuery } from "@domain/interfaces/message-interface";

export default function searchMessageUseCase (query: SearchMessageQuery) {
  const messageRepository = repository.get<MessageRepository>(MessageRepository);

  return messageRepository.search(query);
}
