import repository from "@config/repository";
import ConversationRepository from "@domain/repositories/conversation-repository";

import { SearchConversationQuery } from "@domain/interfaces/conversation-interface";

export default function searchConversationUseCase (query: SearchConversationQuery) {
  const conversationRepository = repository.get<ConversationRepository>(ConversationRepository);

  return conversationRepository.search(query);
}
