import MediaRepository from "@domain/repositories/media-repository";
import TheAdvanceMediaService, { TheAdvanceMediaResponse } from "@infrastructure/network/theadvance-media";

import { injectable } from "inversify";

@injectable()
export default class TheAdvanceMediaRepository implements MediaRepository {
  private api = new TheAdvanceMediaService();
  async uploadMedia (file: File): Promise<TheAdvanceMediaResponse> {
    return await this.api.upload("/chat/upload", file, file.name);
  }

  async uploadRecord (blob: Blob, duration = 0): Promise<TheAdvanceMediaResponse> {
    const file = new File([blob], `${Date.now()}_record.wav`, { type: "audio/wav" });

    return await this.api.upload("/chat/upload", file, file.name, duration);
  }

  async uploadAvatar (file: File): Promise<TheAdvanceMediaResponse> {
    return await this.api.upload("/chat/upload/avatar", file, file.name);
  }
}
